import React from "react";
import {Outlet} from "react-router-dom";
import NavBar from "./navbar/NavBar";
import Footer from "./footer/Footer";
import {ToastContainer} from "react-toastify";
import {useSelector} from "react-redux";

function Layout() {
    const menuListData = useSelector((state) => state.webSetting.menuListData);
    // Early return if no menu data is available
    if (!menuListData || menuListData.length === 0) {
        return null;
    }
    return (
        <>
            <NavBar/>
            <Outlet/>
            <Footer />
            <ToastContainer  />
        </>
    )
}

export default Layout;
