import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import {
    actionToGetSEOMetaDataApiCall,
    actionToGetWebsiteContentApiCall,
} from "../../redux/action";

const HelmetComponent = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const seoMetaData = useSelector((state) => state.webSetting.seoMetaData);

    useEffect(() => {
        const { pathname } = location;
        // Fetch SEO and website content data when location changes
        dispatch(actionToGetSEOMetaDataApiCall({ pathname }));
        dispatch(actionToGetWebsiteContentApiCall({ pathname }));
    }, [location, dispatch]);

    // Function to generate Helmet meta tags and title
    const getHelmetData = (seoMeta) => {
        if (!seoMeta || seoMeta.length === 0) return null;

        return seoMeta.map((data, i) => {
            if (data?.name === "title") {
                return <title key={i}>{data.content}</title>;
            }
            return <meta key={i} name={data.name} content={data.content} />;
        });
    };

    return <Helmet>{getHelmetData(seoMetaData)}</Helmet>;
};

export default HelmetComponent;
