import {api} from "../hooks/apiConfig";
import {
    ADD_TO_CART,
    CLEAR_CART,
    LOAD_CART,
    UPDATE_QUANTITY_OF_CART,
    PRODUCTS_DETAIL_DATA,
    PRODUCTS_LIST_DATA,
    REMOVE_FROM_CART,
    SEO_META_DATA,
    WEBSITE_CONTENT,
    MENU_LIST_DATA,
    COMPANY_DATA,
    CATEGORY_DATA,
    GRADE_LIST_DATA,
    CURRICULUM_FILE_LIST_DATA,
    CURRICULUM_LIST_DATA, ADDRESS_LIST, ZIP_CODE_LIST, STATE_LIST, CITY_LIST, ORDER_LIST_DATA, WEBSITE_LOADING
} from "../constant";
import {parseJwt} from "../utility/jwtUtils";
export const actionToGetCompanyListApiCall = () => async (dispatch,getState) => {
    let company_id = getState().webSetting.company_id;
    let companyData = getState().webSetting.companyData;
    if(!companyData){
        dispatch({ type: COMPANY_DATA, payload: 'loading' });
        const {data} = await api.post(`common-log/get-company-list`,{id:company_id});
        dispatch({ type: COMPANY_DATA, payload: data.length && data[0] });
    }
    return true;
}
export const actionToGetMenuListApiCall = () => async (dispatch,getState) => {
    let company_id = getState().webSetting.company_id;
    let menuListData = getState().webSetting.menuListData;
    if(!menuListData) {
        const responses = await api.post(`web-setting/get-website-menu-list`, {source_id: company_id});
        dispatch({type: MENU_LIST_DATA, payload: responses.data});
    }
    return true;
}
export const actionToGetSEOMetaDataApiCall = (payload) => async (dispatch,getState) => {
    let company_id = getState().webSetting.company_id;
    const {data} = await api.post(`web-setting/get-seo-meta-data-website`,{source_id:company_id,pathname:payload.pathname});
    dispatch({ type: SEO_META_DATA, payload: data });
    return data;
}
export const actionToGetWebsiteContentApiCall = (payload) => async (dispatch,getState) => {
    let company_id = getState().webSetting.company_id;
    dispatch({type: WEBSITE_LOADING,payload:true});
    const {data} = await api.post(`web-setting/get-website-section-content`,{source_id:company_id,pathname:payload.pathname});
    dispatch({ type: WEBSITE_CONTENT, payload: data });
    dispatch({type: WEBSITE_LOADING,payload:false});
    return data;
}
export const actionToGetCategoriesDataApiCall = () => async (dispatch,getState) => {
    let company_id = getState().webSetting.company_id;
    let categoryListData = getState().webSetting.categoryListData;
    if (!categoryListData){
        const {data} = await api.post(`web-setting/get-website-categories`,{source_id:company_id});
        dispatch({ type: CATEGORY_DATA, payload: data });
    }
    return true;
}
export const actionToGetCurriculumDataApiCall = () => async (dispatch,getState) => {
    let company_id = getState().webSetting.company_id;
    let curriculumListData = getState().webSetting.curriculumListData;
    if (!curriculumListData) {
        const {data} = await api.post(`curriculum/get-website-curriculum`, {source_id: company_id});
        dispatch({type: CURRICULUM_LIST_DATA, payload: data});
    }
    return true;
}
export const actionToGetGradesDataApiCall = () => async (dispatch,getState) => {
    let gradeListData = getState().webSetting.gradeListData;
    if(!gradeListData){
        const {data} = await api.post(`curriculum/get-website-grades`);
        dispatch({ type: GRADE_LIST_DATA, payload: data });
    }
    return true;
}
export const actionToGetCurriculumFileDataByIdApiCall = (payload) => async (dispatch) => {
    const {data} = await api.post(`curriculum/get-curriculum-file-by-curriculum-id`,payload);
    dispatch({ type: CURRICULUM_FILE_LIST_DATA, payload: data });
    return data;
}
export const actionToGetUserAddressesApiCall = (payload) => async (dispatch) => {
    const {data} = await api.post(`users/get-user-addresses`,payload);
    dispatch({ type: ADDRESS_LIST, payload: data });
    return data;
}
export const actionToAddUserAddressesApiCall = (payload) => async (dispatch) => {
    const {data} = await api.post(`users/add-user-addresses`,payload);
    dispatch(actionToGetUserAddressesApiCall({user_id:payload?.user_id}));
    return data;
}

export const actionToUpdateProfileApiCall = (payload) => async (dispatch) => {
    const {data} = await api.post(`users/update-profile`,payload);
  //get user data and update localstorage too and setAuth  dispatch(actionToGetUserAddressesApiCall({user_id:payload?.user_id}));
    return data;
}
export const actionToGetPinCodeListApiCall = () => async (dispatch,getState) => {
    let zipCodeList = getState().common.zipCodeList;
    if(!zipCodeList){
        const {data} = await api.post(`common/get-pin-code-list`);
        dispatch({ type: ZIP_CODE_LIST, payload: data });
    }
    return false;
}
export const actionToGetStateListApiCall = () => async (dispatch,getState) => {
    let stateList = getState().common.stateList;
    if(!stateList) {
        const {data} = await api.post(`common/get-state-list`);
        dispatch({type: STATE_LIST, payload: data});
    }
    return true;
}
export const actionToGetCityListApiCall = () => async (dispatch,getState) => {
    let cityList = getState().common.cityList;
    if(!cityList) {
        const {data} = await api.post(`common/get-city-list`);
        dispatch({type: CITY_LIST, payload: data});
    }
    return true;
}

export const actionToLogin =  (email, password) => async (dispatch,getState) =>{
    let company_id = getState().webSetting.company_id;
    return api.post("/auth/website-login", {
            email,
            password,
        source_id:company_id
        })
        .then(async (response) => {
            if (response.data.accessToken) {
                localStorage.setItem("user", JSON.stringify(response.data));
                // Parse the JWT token
                const parsedData = parseJwt(response.data.accessToken);
                let user_id =  parsedData.user.id;
                dispatch(actionToSyncCart(user_id));
                return parsedData.user;
            }
            return response.data;
        });
}
export const actionToLogout = () => (dispatch)=>{
    api.delete("/auth/logout");
    dispatch(actionToClearCart());
}
export const actionToSignup = (param) => (dispatch,getState)=> {
    let company_id = getState().webSetting.company_id;
    param['source_id'] = company_id;
    return api.post("/auth/website-signup", param)
        .then((response) => {
            if (response.data.accessToken) {
                localStorage.setItem("user", JSON.stringify(response.data));
                // Parse the JWT token
                const parsedData = parseJwt(response.data.accessToken);
                let user_id =  parsedData.user.id;
                dispatch(actionToSyncCart(user_id));
                return parsedData.user;
            }
            return response.data;
        });
};
export const actionToGetProductsListApiCall = () => async (dispatch,getState) => {
    let company_id = getState().webSetting.company_id;
    const {data} = await api.post(`products/get-website-product-list`,{source_id:company_id});
    dispatch({ type: PRODUCTS_LIST_DATA, payload: data });
}
export const actionToGetProductsDetailsApiCall = (payload) => async (dispatch,getState) => {
    payload['source_id']=getState().webSetting.company_id;
    const {data} = await api.post(`products/get-website-product-detail`,payload );
    dispatch({ type: PRODUCTS_DETAIL_DATA, payload: data.length > 0 ? data[0] : {} });
}
export const actionToSyncCart =(user_id)=>async(dispatch,getState)=>{
    const {data} = await api.post(`products/get-website-cart-items`,{user_id:user_id});
    const { cartItems } = getState().product;
    let payload=[];
    let payloadToUpdateInDb=[];
    let productIdArray =[];
    let cartId='';
    if(data?.length > 0){
        cartId =data[0].id;
        await data[0].jsdata?.map(item=>{
            const existingItem = cartItems.find(cartItem => cartItem.product_id === item.product_id);
            if (existingItem) {
                productIdArray.push(item.product_id);
                let existingItemQuantity = Number(existingItem.quantity) + Number(item.quantity);
                payload.push({id:item.id, product_id:item.product_id, quantity:existingItemQuantity});
                payloadToUpdateInDb.push({id:item.id, product_id:item.product_id, quantity:existingItemQuantity});
            }else{
                payload.push({id:item.id, product_id:item.product_id, quantity:item.quantity});
            }
            return payload;
        });
    }

    if(cartItems.length > 0){
        cartItems.map(items=>{
            if(!productIdArray.includes(items.product_id)){
                payload.push(items);
                payloadToUpdateInDb.push(items);
            }
            return payload;
        })
    }
    dispatch({type: LOAD_CART, payload: payload});

    if(payloadToUpdateInDb.length >0){
        dispatch(actionAddToCartInDatabase(payloadToUpdateInDb,user_id,cartId))
    }
}
export const actionToAddToCart = (item,user_id) =>(dispatch,getState)=>{
    const { cartItems,cartId } = getState().product;

    // Check if the item is already in the cart
    const existingItem = cartItems?.find(cartItem => cartItem.product_id === item.product_id);
    let payload = {}
    if (existingItem) {
        // Update the quantity
        let existingItemQuantity = Number(existingItem.quantity) + Number(item.quantity);
        payload =[{id:existingItem.id, product_id:item.product_id, quantity:existingItemQuantity}]
        dispatch({
            type: UPDATE_QUANTITY_OF_CART,
            payload: { product_id:item.product_id, quantity:existingItemQuantity},
            user_id:user_id
        });
    } else {
        payload =[item];
        // Add new item to the cart
        dispatch({
            type: ADD_TO_CART,
            payload: item,
            user_id:user_id
        });
    }
    if(user_id){
       dispatch(actionAddToCartInDatabase(payload,user_id,cartId))
    }
}
export const actionToUpdateQuantityCart = (payload,user_id)=> (dispatch,getState)=>{
    const { cartId } = getState().product;
    dispatch({
        type: UPDATE_QUANTITY_OF_CART,
        payload: { product_id:payload.product_id, quantity:payload.quantity},
        user_id:user_id
    });

    if(user_id){
        let param =[{id:payload.id, product_id:payload.product_id, quantity:payload.quantity}];
        dispatch(actionAddToCartInDatabase(param,user_id,cartId))
    }
}
export const actionAddToCartInDatabase = (payload,user_id,cartId) =>()=>{
    api.post(`products/add-to-cart`,{items:payload,user_id:user_id,cart_id:cartId} );
}
export const actionToRemoveFromCart = (product_id,user_id,id) => (dispatch)=> {
    dispatch({type: REMOVE_FROM_CART, payload: product_id,user_id:user_id});
    if(user_id){
        api.post(`products/remove-from-cart`,{cart_item_id:[id],user_id:user_id} );
    }
}

export const actionToLoadCart = (cart,user_id) =>async(dispatch,getState)=> {
    if(user_id){
        const company_id = getState().webSetting.company_id;
        const {data} = await api.post(`products/get-website-cart-items`,{user_id:user_id,source_id:company_id});
        if(data.length > 0){
            dispatch({type: LOAD_CART, payload: data[0].jsdata,cart_id:data[0].id});
        }
    }else
    dispatch({type: LOAD_CART, payload: cart});
}
export const actionToClearCart = (user_id) =>(dispatch)=> {
    //const { cartItems } = getState().product;
    dispatch({type: CLEAR_CART});
    if(user_id){
//let idArray=cartItems.filter();
     //   api.post(`products/remove-from-cart`,{cart_item_id:idArray,user_id:user_id} );
    }
}
export const actionToPayByPhonePe = (payload) =>async ()=>{
   return api.post(`payment/pay-by-phonepe`,payload)
}
export const actionToGetTransactionStatus = (payload) => () =>{
    return api.post(`payment/payment-status/${payload.transactionId}/${payload.userId}/${payload.orderId}`,payload);
}

export const actionToGetOrderDetailsApiCall = (payload) => async (dispatch,getState) => {
    const {data} = await api.post(`products/get-order-detail`,payload );
    dispatch({ type: ORDER_LIST_DATA, payload: data });
}
