import { combineReducers } from 'redux';
import productReducer from "./productReducer";
import {webSettingReducer} from "./webSettingReducer";
import {commonReducer} from "./commonReducer";
const rootReducer = combineReducers({
    product: productReducer,
    webSetting: webSettingReducer,
    common: commonReducer
    // Add other reducers here
});

export default rootReducer;
