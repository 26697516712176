import {ADDRESS_LIST, CITY_LIST, STATE_LIST, ZIP_CODE_LIST} from "../constant";

const initialState = {
    stateList:false,
    cityList:false,
    zipCodeList:false,
    addressListData: [],
}
export  const commonReducer = (state = initialState, action) => {
    switch (action.type) {
       case STATE_LIST:
            return { ...state, stateList: action.payload };
        case CITY_LIST:
            return { ...state, cityList: action.payload };
        case ZIP_CODE_LIST:
            return { ...state, zipCodeList: action.payload };
        case ADDRESS_LIST:
            return { ...state, addressListData: action.payload };
        default:
            return state
    }
}
