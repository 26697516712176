import './App.css';
import React, { Suspense, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { parseJwt } from "./redux/utility/jwtUtils";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import {
  actionToGetCompanyListApiCall,
  actionToGetMenuListApiCall,
  actionToLoadCart
} from "./redux/action";
import useAuth from "./redux/hooks/useAuth";
import { useEffectOnce } from "./redux/hooks/useEffectOnce";
import HelmetComponent from "./components/layout/HelmetComponent";
import Layout from "./components/layout/Layout";
import RequireAuth from "./components/auth/RequireAuth";
import ErrorPage from "./pages/ErrorPage";
import LazyComponent from "./LazyComponent";
import Loader from "./components/Loader/Loader";
import TagManager from "react-gtm-module";

// Initialize Google Tag Manager
TagManager.initialize({ gtmId: 'G-D1Q2ZQVFN1' });

const ROLES = {
  Student: 4,
  Teacher: 3,
  School: 2,
  Admin: 1,
  Customer: 7
};

function App() {
  const { setAuth } = useAuth();
  const dispatch = useDispatch();
  const menuListData = useSelector((state) => state.webSetting.menuListData);

  // Memoize allowed roles
  const allowedRoles = useMemo(() => [
    ROLES.Student,
    ROLES.Teacher,
    ROLES.School,
    ROLES.Customer,
    ROLES.Admin,
  ], []);

  // Authorization and loading cart logic
  const authorized = () => {
    const userData = localStorage.getItem('user');
    if (userData) {
      const data = JSON.parse(userData);
      if (data.accessToken) {
        const user = parseJwt(data.accessToken);
        setAuth(user.user);

        // Load cart
        const storedCart = JSON.parse(localStorage.getItem('cart'));
        if (storedCart || user.user.id) {
          dispatch(actionToLoadCart(storedCart, user.user.id));
        }
      }
    }
  };

  useEffectOnce(() => {
    dispatch(actionToGetCompanyListApiCall());
    dispatch(actionToGetMenuListApiCall());
    authorized();
  });

  // Memoize route configurations
  const routesConfig = useMemo(() => {
    if (!menuListData) return null;

    const privateRoutes = [];
    const publicRoutes = [];

    menuListData.forEach((route) => {
      const Component = route.component_path ? LazyComponent(route.component_path) : null;
      const routePath = route.exact === '1' ? route.url : `${route.url}/*`;

      if (Component) {
        if (route.display_only_logged_in === '1') {
          privateRoutes.push({ path: routePath, component: Component, exact: route.exact === '1' });
        } else {
          publicRoutes.push({ path: routePath, component: Component, exact: route.exact === '1' });
        }
      }
    });

    return { privateRoutes, publicRoutes };
  }, [menuListData]);

  if (!routesConfig) return null;

  const { privateRoutes, publicRoutes } = routesConfig;

  return (
      <BrowserRouter>
        <HelmetComponent />
        <Suspense fallback={<Loader />}>
          <Routes>
            <Route path={''} element={<Layout />}>
              {/* Public Routes */}
              {publicRoutes.map(({ path, component: Component, exact }) => (
                  <Route key={path} path={path} element={<Component />} exact={exact} />
              ))}

              {/* Private Routes */}
              <Route element={<RequireAuth allowedRoles={allowedRoles} />}>
                {privateRoutes.map(({ path, component: Component, exact }) => (
                    <Route key={path} path={path} element={<Component />} exact={exact} />
                ))}
              </Route>

              {/* Fallback Error Page */}
              <Route path="/*" element={<ErrorPage />} />
            </Route>
          </Routes>
        </Suspense>
      </BrowserRouter>
  );
}

export default App;
