import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { Link } from 'react-router-dom';

const CompanyLogo = () => {
   const companyData = useSelector((state) => state.webSetting.companyData);

    // Memoize the entire menu to avoid recalculating on every render
    const memoizedLogo = useMemo(() => {
        if (!companyData || companyData.length === 0) return null;

        return  <Link to={'/'} key={companyData?.id}>
            <img
                src={companyData?.logo}
                alt={`${companyData?.name} logo`}
                className="w-52 hover:scale-105 transition-all"
            />
        </Link>;
    }, [companyData]);

    if (!memoizedLogo) {
        return null;
    }

    return (
        <>
            {/* Company Logo */}
            {memoizedLogo}
        </>
    );
};

export default CompanyLogo;
