export const WEBSITE_CONTENT = "WEBSITE_CONTENT";
export const COMPANY_DATA = "COMPANY_DATA";
export const MENU_LIST_DATA = "MENU_LIST_DATA";
export const GRADE_LIST_DATA = "GRADE_LIST_DATA";
export const CURRICULUM_FILE_LIST_DATA = "CURRICULUM_FILE_LIST_DATA";
export const CURRICULUM_LIST_DATA = "CURRICULUM_LIST_DATA";
export const WEBSITE_LOADING = "WEBSITE_LOADING";
export const SEO_META_DATA = "SEO_META_DATA";
export const CATEGORY_DATA = "CATEGORY_DATA";
export const PRODUCTS_LIST_DATA = "PRODUCTS_LIST_DATA";
export const ORDER_LIST_DATA = "ORDER_LIST_DATA";
export const PRODUCTS_DETAIL_DATA = "PRODUCTS_DETAIL_DATA";
export const ADDRESS_LIST = "ADDRESS_LIST";
export const ZIP_CODE_LIST = "ZIP_CODE_LIST";
export const STATE_LIST = "STATE_LIST";
export const CITY_LIST = "CITY_LIST";
export const ADD_TO_CART = 'ADD_TO_CART';
export const UPDATE_QUANTITY_OF_CART = 'UPDATE_QUANTITY_OF_CART';
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART';
export const LOAD_CART = 'LOAD_CART';
export const CLEAR_CART = 'CLEAR_CART';
