import React from "react";
import { useSelector } from "react-redux";

const StickyFooter =() =>{
    const companyData = useSelector((state) => state.webSetting.companyData);

    // Social media links array
    const socialLinks = [
        { platform: "Instagram", link: companyData?.instagram_link, icon: "fab fa-instagram" },
        { platform: "Facebook", link: companyData?.facebook_link, icon: "fab fa-facebook-f" },
        { platform: "Whatsapp", link: companyData?.whatsapp_link, icon: "fab fa-whatsapp" },
        { platform: "Youtube", link: companyData?.youtube_link, icon: "fab fa-youtube" },
        { platform: "Linkedin", link: companyData?.linkedin_link, icon: "fab fa-linkedin" }
    ];

    return (
        <div className="sticky-icon">
            {socialLinks.map(
                (social, index) =>
                    social.link && (
                        <a
                            key={index}
                            className={social.platform}
                            href={social.link}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <i className={social.icon}></i> {social.platform}
                        </a>
                    )
            )}
        </div>
    );
}
export default React.memo(StickyFooter);