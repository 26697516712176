import React from 'react';
const ErrorPage = () => {
    return (
        <div className="container">
            404 Page
        </div>

    );
};

export default ErrorPage;
