import React, { useMemo } from "react";
import { FaEnvelope, FaMobileAlt } from "react-icons/fa";
import { FaLocationArrow } from "react-icons/fa6";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import StickyFooter from "./StickyFooter";

const Footer = () => {
    const menuListData = useSelector((state) => state.webSetting.menuListData);
    const companyData = useSelector((state) => state.webSetting.companyData);

    // Memoize menu data to prevent unnecessary recalculations
    const { quickLinks, importantLinks } = useMemo(() => {
        const quickLinks = menuListData?.filter((data) => data.footer_menu === "1");
        const importantLinks = menuListData?.filter(
            (data) => data.second_footer_menu === "1"
        );
        return { quickLinks, importantLinks };
    }, [menuListData]);



    // Reusable component for rendering menu links
    const RenderLinks = ({ title, links }) => (
        <div className="py-8 px-4">
            <h1 className="text-xl font-bold sm:text-left mb-3">{title}</h1>
            <ul className="space-y-3">
                {links.map((data, index) => (
                    <li key={index}>
                        <Link
                            to={data.url}
                            className="text-gray dark:text-gray hover:text-primary duration-300"
                        >
                            {data.name}
                        </Link>
                    </li>
                ))}
            </ul>
        </div>
    );

    return (
        <>
            <section className="bg-light">
                <div className="lg:m-10">
                    <div className="grid md:grid-cols-3 pb-20 pt-5">
                        {/* Company Logo and Description */}
                        <div className="py-8 px-4">
                            <img
                                src={companyData?.logo}
                                alt={`${companyData?.name} footer logo`}
                                className="w-60"
                            />
                            <p className="text-black dark:text-gray lg:pr-24 pt-3">
                                {companyData?.description}
                            </p>
                        </div>

                        {/* Quick Links and Important Links */}
                        <div className="col-span-2 grid grid-cols-2 sm:grid-cols-3 md:pl-10">
                            <RenderLinks title="Quick Links" links={quickLinks} />
                            <RenderLinks title="Important Links" links={importantLinks} />

                            {/* Contact Information */}
                            <div className="py-8 px-4 col-span-2 sm:col-auto">
                                <h1 className="text-xl font-bold sm:text-left mb-3">Contact</h1>
                                <div>
                                    {companyData?.address && (
                                        <Link to={companyData?.map_link}>
                                            <div className="flex items-center gap-3">
                                                <FaLocationArrow className="text-gray hover:text-primary" />
                                                <p className="text-gray hover:text-primary">
                                                    {companyData?.address}
                                                </p>
                                            </div>
                                        </Link>
                                    )}
                                    {companyData?.phone && (
                                        <Link to={`tel:${companyData?.phone}`}>
                                            <div className="flex items-center gap-3 mt-6">
                                                <FaMobileAlt className="text-gray hover:text-primary" />
                                                <p className="text-gray hover:text-primary">
                                                    {companyData?.phone}
                                                </p>
                                            </div>
                                        </Link>
                                    )}
                                    {companyData?.email && (
                                        <Link to={`mailto:${companyData?.email}`}>
                                            <div className="flex items-center gap-3 mt-6">
                                                <FaEnvelope className="text-gray hover:text-primary" />
                                                <p className="text-gray hover:text-primary">
                                                    {companyData?.email}
                                                </p>
                                            </div>
                                        </Link>
                                    )}
                                    {companyData?.landline && (
                                        <Link to={`tel:${companyData?.landline}`}>
                                            <div className="flex items-center gap-3 mt-6">
                                                <FaMobileAlt className="text-gray hover:text-primary" />
                                                <p className="text-gray hover:text-primary">
                                                    {companyData?.landline}
                                                </p>
                                            </div>
                                        </Link>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <StickyFooter />
        </>
    );
};

export default React.memo(Footer);
